<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c" style="padding-left:0.3rem">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name flex-c-c-s" style="margin-left:0.3rem">
          <div class="order_del_status flex-r-c-c">
            填写发票信息
          </div>
        </div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 类型 -->
    <div class="invoice_box flex-c-s-s">
      <div class="invoice_box_title">税票类型</div>
      <div class="invoice_box_choose flex-r-sa-c">
        <div :class="choosed1 === 1 ? 'invoice_box_choose_item choosed_actived':'invoice_box_choose_item'"
             @click="change01(1)"
        >
          增值税电子普通发票
          <van-image v-if="choosed1 === 1" class="invoice_box_choose_item_icon" width="0.43rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/invoice/invoice_icon.png"
          />
        </div>
        <div :class="choosed1 === 2 ? 'invoice_box_choose_item choosed_actived':'invoice_box_choose_item'"
             @click="change01(2)"
        >
          增值税电子专用发票
          <van-image v-if="choosed1 === 2" class="invoice_box_choose_item_icon" width="0.43rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/invoice/invoice_icon.png"
          />
        </div>
      </div>

      <!-- <div class="invoice_box_title" style="margin-top:0.37rem">开票方式</div>
      <div class="invoice_box_choose flex-r-sa-c">
        <div :class="choosed2 === 2 ? 'invoice_box_choose_item choosed_actived':'invoice_box_choose_item'"
             @click="change02(2)"
        >按商品类别
          <van-image v-if="choosed2 === 2" class="invoice_box_choose_item_icon" width="0.43rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/invoice/invoice_icon.png"
          />
        </div>
        <div :class="choosed2 === 1 ? 'invoice_box_choose_item choosed_actived':'invoice_box_choose_item'"
             @click="change02(1)"
        >按商品明细
          <van-image v-if="choosed2 === 1" class="invoice_box_choose_item_icon" width="0.43rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/invoice/invoice_icon.png"
          />
        </div>
      </div> -->
    </div>
    <!-- 信息 -->
    <div class="invoice_info flex-c-s-s">
      <div class="invoice_box_title">税票类型</div>

      <van-form style="width:100%;margin-top:0.2rem" @submit="onSubmit">
        <van-field v-model="subInfo.invoicesCompanyName" name="invoicesCompanyName" placeholder="请填写发票抬头名称"
                   :readonly="true" :rules="[{ required: true }]" @click="toCompanyList"
        >
          <div slot="label">公司名称<span style="color:#E2432D">*</span></div>
        </van-field>
        <van-field v-model="subInfo.invoicesTaxNum" name="invoicesTaxNum" label="公司税号" placeholder="请输入纳税人识别号"
                   :rules="[{ required: true }]"
        >
          <div slot="label">公司税号<span style="color:#E2432D">*</span></div>
        </van-field>
        <van-field v-model="subInfo.bankAccount" name="bankAccount" label="银行账户" placeholder="请填写银行账户"
                   :rules="[{ required: choosed1 === 2 }]"
        >
          <div slot="label">银行账户<span v-if="choosed1 === 2" style="color:#E2432D">*</span></div>
        </van-field>
        <van-field v-model="subInfo.bankName" name="bankName" label="开户银行" placeholder="请输入开户行名称"
                   :rules="[{ required: choosed1 === 2 }]"
        >
          <div slot="label">开户银行<span v-if="choosed1 === 2" style="color:#E2432D">*</span></div>
        </van-field>
        <van-field v-model="subInfo.registeredAddress" name="registeredAddress" placeholder="请输入公司注册地址">
          <div slot="label">注册地址</div>
        </van-field>
        <van-field v-model="subInfo.officeNumber" name="officeNumber" placeholder="请输入公司注册电话">
          <div slot="label">公司电话</div>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" color="#0767AB">提交开票申请</van-button>
        </div>
      </van-form>
    </div>

    <!-- 确认开票信息 popup -->
    <van-popup v-model="popupshow" round position="bottom" :style="{ height: '15.8rem' }" class="popup_bg">
      <van-image width="10rem" height="3.4rem" src="https://oss.xiaoyi120.com/shop2.0/invoice/popup_bg02.png"
                 class="popup_img"
      />
      <div class="popup_info">
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">发票类型</div>
          <div class="popup_info_item_msg">{{ ['','增值税电子普通发票','增值税电子专用发票'][subInfo.invoicesType] }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">开票方式</div>
          <div class="popup_info_item_msg">{{ ['','按商品明细','按商品类别'][subInfo.invoicesMethod] }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">公司名称</div>
          <div class="popup_info_item_msg">{{ subInfo.invoicesCompanyName }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">公司税号</div>
          <div class="popup_info_item_msg">{{ subInfo.invoicesTaxNum }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">银行账户</div>
          <div class="popup_info_item_msg">{{ subInfo.bankAccount || '-' }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">开户银行</div>
          <div class="popup_info_item_msg">{{ subInfo.bankName || '-' }}</div>
        </div>
        <div class="popup_info_item flex-r-s-s">
          <div class="popup_info_item_name">注册地址</div>
          <div class="popup_info_item_msg" style="max-width:7rem">{{ subInfo.registeredAddress || '-' }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">公司电话</div>
          <div class="popup_info_item_msg">{{ subInfo.officeNumber || '-' }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">商品实付</div>
          <div class="popup_info_item_msg">{{ orderPrice }}</div>
        </div>
        <div class="popup_info_item flex-r-s-c">
          <div class="popup_info_item_name">运费金额</div>
          <div class="popup_info_item_msg">{{ expressPrice }}</div>
        </div>

        <div class="popup_info_tips">
          自助开票仅限下单后180天内申请；发票仅限整单开具，不支持单商品开具；已提交开票的订单无法自行提交退款。
        </div>

        <div class="popup_info_button flex-r-sa-c">
          <div class="popup_info_button_cancer" @click="popupshow = false">取消提交</div>
          <div class="popup_info_button_sub" @click="sub">确认提交</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
import { submitInvoices } from '@/api/order'
import { enterprise_info } from '@/api/public'
export default {
  name: 'ApplyInvoice',
  data() {
    return {
      orderId: '',
      companyId: '',
      orderPrice: '',
      expressPrice: '',

      choosed1: 1,
      choosed2: 2,

      subInfo: { invoicesType: 1, invoicesMethod: 1 },

      popupshow: false
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.companyId = this.$route.query.companyId
    this.orderPrice = this.$route.query.orderPrice
    this.expressPrice = this.$route.query.expressPrice
  },
  activated() {
    console.log('keepalive applyInvoice', sessionStorage.getItem('nameInvoice'))
    if (sessionStorage.getItem('nameInvoice')) {
      this.$set(this.subInfo, 'invoicesCompanyName', sessionStorage.getItem('nameInvoice'))
      this.getInfo(sessionStorage.getItem('nameInvoice'))
    }
  },
  deactivated() {
    console.log('keepalive 离开classify')
  },
  methods: {
    backPath() { window.history.back() },
    change01(type) { this.choosed1 = type; this.subInfo.invoicesType = type },
    change02(type) { this.choosed2 = type; this.subInfo.invoicesMethod = type },
    toCompanyList() { this.$router.push('/invoice/chooseCompany') },
    getInfo(companyName) {
      enterprise_info({ companyName: companyName }).then(res => {
        this.$set(this.subInfo, 'registeredAddress', res.data.address)
        this.$set(this.subInfo, 'bankAccount', res.data.bankAccount)
        this.$set(this.subInfo, 'bankName', res.data.bankName)
        this.$set(this.subInfo, 'invoicesCompanyName', res.data.companyName)
        this.$set(this.subInfo, 'invoicesTaxNum', res.data.creditCode)
        this.$set(this.subInfo, 'id', res.data.id)
        this.$set(this.subInfo, 'legalName', res.data.legalName)
        this.$set(this.subInfo, 'officeNumber', res.data.phoneNumber)
      })
      sessionStorage.removeItem('nameInvoice')
    },
    onSubmit(values) {
      console.log(values)
      this.popupshow = true
    },
    sub() {
      this.subInfo.orderId = this.orderId
      submitInvoices(this.subInfo).then(res => {
        this.$toast({ message: '提交成功！', duration: 2 * 1000 })
        this.$router.replace('/order/orderDetail?id=' + this.orderId)
      })
    }

  }
}
</script>

<style lang="scss">
.van-popup {
  border-radius: 0.27rem;
}
</style>

